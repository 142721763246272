import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

const Sort = ({items, label}) => {

  const selected = items[0];

  return (
    <div className="flex items-center">

      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="focus:outline-none focus:ring-none">
            <div className="flex flex-row items-center text-blue-500">
              {label !== ""
                ? ( <span className="mr-1">{label}:</span> )
                : null
              }
              {selected.option}
              <i className="fa-light fa-angle-down ml-2 text-lg"></i>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-1 w-48 text-left rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none focus:ring-none">
          {items && items.map((item) => (
              <Menu.Item key={item.option}>
                  <Link
                    to="#"
                    className='block py-2 px-4 text-blue-500 font-normal hover:bg-blue-50'
                  >
                    {item.option}
                  </Link>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
export default Sort;
