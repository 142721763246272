import React from "react";

const DropDown = ({ label, firstOption, options }) => {
  console.log({options});
  return (
    <div>
      <label
        for={label}
        class="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        id={label}
        class="bg-white h-10 pl-4 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md"
      >
      {options && options.map( ( option, index ) => {
        return (
        <option>{option.item}</option>
        )})}
      </select>
    </div>
  );
};

export default DropDown;
