import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import {
  Link,
  useParams,
} from 'react-router-dom';

import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';

import TopNav from '../../components/Navigation/TopNav';
import CourseDetail from '../../components/Panels/CourseDetail';
// import Tools from "../../components/ContentTools/PathTools"; Post MVP
import Reviews from '../../components/Reviews/Reviews';
import PathReviews from '../../data/PathReviews';

const baseURL = "https://62ab6284bd0e5d29af0f1cce.mockapi.io/Catalog";

export default function Details() {
  const { id } = useParams();

  const [course, setCourse] = useState(null);

  useEffect(() => {
    axios.get(`${baseURL}?id=${id}`).then((response) => {
      setCourse(response.data);
    });
  }, []);

  if (!course) return null;

  return (
    <div>
      <div className="fixed w-full z-20">
        <TopNav />
      </div>

      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 lg:p-4 mt-2 lg:mt-0">
        {course.map((item) => (
          <div key={item.id}>
            <div className="mb-4">
              <PageTitle>
                <i className="fa-regular fa-book-open text-orange-500 mr-2"></i>
                {item.title}
              </PageTitle>
            </div>
            <MainCard type="main">
              <div className="grid grid-cols-1 lg:grid-cols-5 -m-4">
                <div className="p-3 lg:p-4 border-0 lg:border-r border-gray-300 col-span-1 lg:col-span-3">
                  <div className="relative w-full bg-red-500 rounded-lg overflow-hidden">
                    <div
                      className="w-full object-center object-cover h-40 sm:h-80 lg:h-96"
                      style={{ backgroundColor: `${item.background}` }}
                    >
                      <div className="h-full flex items-center justify-center text-8xl lg:text-8xl lg:text-9xl text-white">
                        <i className={item.icon}></i>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex  mb-2 flex-row">
                      <div className="w-12 flex justify-center text-gray-300 text-3xl">
                        <i class="fa-regular fa-circle"></i>
                      </div>
                      <div className="text-lg font-medium">
                        7 Courses, 2h 18mins
                      </div>
                    </div>
                    {course.map((item) => {
                      return (
                        <div>
                          {item.courses.map((course) => {
                            return (
                              <div className="flex flex-row" key={course.id}>
                                <div className="flex items-center relative">
                                  <div className="border border-gray-300 h-full absolute left-[21px]"></div>
                                  <div className="dots w-12 z-10">
                                    <div className="flex items-center justify-center h-full">

                                      {course.status === "Completed" ?
                                      (
                                        <div className="rounded-full bg-white text-lg p-1 text-green-500">
                                          <i class="fa-solid fa-circle-check"></i>
                                        </div>
                                      ) : course.status === "Playing" ? (
                                        <div className="rounded-full bg-white text-lg p-1 text-blue-500">
                                          <i class="fa-regular fa-circle"></i>
                                        </div>
                                      ) : (
                                        <div className="rounded-full bg-white text-lg p-1 text-gray-400">
                                          <i class="fa-regular fa-circle"></i>
                                        </div>
                                      )
                                      }

                                    </div>
                                  </div>
                                </div>

                                <div className="box grow">
                                  <div className="border rounded-lg border-gray-300 p-4 my-2 flex flex-row gap-4">
                                    <div>
                                      <div className="
                                        bg-gray-100
                                        rounded-lg
                                        h-32
                                        w-48
                                        bg-cover
                                        bg-center
                                        bg-no-repeat"
                                        style={{ backgroundImage: `url(${course.thumb})` }}>
                                      </div>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="flex flex-shrink">
                                        <div className="bg-blue-100 px-2 py-1 text-sm mb-3">
                                          {course.type}
                                        </div>
                                      </div>
                                      <div className="font-medium">
                                        <Link to={`/#/catalog/course-details/${course.id}`}>{course.title}</Link>
                                      </div>

                                      <div className="text-ellipsis overflow-hidden mb-3 h-6">
                                        {course.summary}
                                      </div>

                                      <div className="text-gray-600">
                                        <i className="fa-light fa-clock"></i> {course.duration} mins
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                    <div className="flex  mt-2 mb-3 flex-row">
                      <div className="w-12 flex justify-center text-gray-300 text-3xl">
                        <i class="fa-regular fa-circle-check"></i>
                      </div>
                      <div className="text-lg font-medium text-gray-300">
                        Congratulations! You completed this learning path.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-3 pb-3 lg:p-4 col-span-1 lg:col-span-2">
                  <div className="text-lg font-medium mb-4">
                    Learning Path Details
                  </div>
                  <div>
                    <CourseDetail
                      id={item.id}
                      img={item.img}
                      type={item.type}
                      background={item.background}
                      icon={item.icon}
                      duration={item.duration}
                      release={item.release}
                      rating={item.rating}
                      ratingCount={item.ratingCount}
                      description={item.description}
                      title={item.title}
                      enrolled={item.enrolled}
                      price={item.price}
                    />
                  </div>
                  <div className="text-lg font-medium my-4">
                    Reviews
                  </div>
                  <Reviews reviews={PathReviews} />
                  <div className="ml-16">
                    <Link to="/">Show All Reviews</Link>
                  </div>
                </div>
              </div>
            </MainCard>
          </div>
        ))}
      </div>
    </div>
  );
}
