import React from "react";
import Avatar from "../Avatar/Avatar";
import StarRatings from "react-star-ratings";

const Reviews = ({reviews}) => {
  return (
    <div>

      {reviews.map((item) => (
        <div className="py-4 border-b last:border-none border-gray-300 relative" key={item.timeStamp}>

          <div className="flex flex-row gap-3">
            <div className="flex flex-shrink-0">
              <Avatar img={item.img} size={50} color="gray-300" />
            </div>
            <div className="w-full">
              <div className="font-medium">{item.name}</div>
              <div className="my-1">
                <StarRatings
                  rating={item.rating}
                  starRatedColor="#EEB32F"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="1px"
                />
              </div>
              <div>{item.comment}</div>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
};

export default Reviews;
