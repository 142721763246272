import React from 'react';

const BreakPoints = ({display}) => {
  return (
    <div>
    {display === "true" ? (
      <div className="h-9 w-9 bg-gray-900 text-white rounded-full flex items-center justify-center relative mr-4">
        <div className="sm:visible md:hidden">SM</div>
        <div className="sm:hidden md:block lg:hidden">MD</div>
        <div className="sm:hidden lg:block xl:hidden">LG</div>
        <div className="sm:hidden xl:block 2xl:hidden">XL</div>
      </div>
      ) : null
    }
  </div>)

}

export default BreakPoints;
