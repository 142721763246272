const reviews = [
  {
    uid: 1,
    name: "Tom Smith",
    comment:
      "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.",
    img: "https://randomuser.me/api/portraits/men/86.jpg",
    rating: 4.6
  },
  {
    uid: 2,
    name: "Tom Cook",
    comment:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    img: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    rating: 3.2
  },
  {
    uid: 3,
    name: "Elizabeth Smally",
    comment:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore.",
    img: "https://randomuser.me/api/portraits/women/9.jpg",
    rating: 4.9
  },
  {
    uid: 4,
    name: "Brad Williams",
    comment:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    img: "https://randomuser.me/api/portraits/men/21.jpg",
    rating: 4.2
  },
];

export default reviews;
