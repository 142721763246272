import React from 'react';

import StarRatings from 'react-star-ratings';

import Button from '@alliancesafetycouncil/asc-button';

const CatalogItem = ({ id, title, type, summary, duration, thumb, release, rating, ratingCount, price, background, icon }) => {

  return (
    <div className="flex flex-row relative">
      <div className="absolute right-0 top-0 text-lg font-medium">

        {price !== 0.00
          ? ("$" + price.toFixed(2))
          : "Free"
        }

      </div>
      {type !== "Learning Path" ?
        (
          <a href={`/#/catalog/course-details/${id}`}>
          <div
            className="
              flex-shrink-0
              w-24
              h-20
              md:w-56
              md:h-40
              lg:w-72
              lg:h-48
              bg-cover
              bg-center
              bg-no-repeat
              rounded-lg"
          style={{ backgroundImage: `url(${thumb})` }}
          ></div>
        </a>
        ) : (
          <a href={`/#/catalog/path-details/${id}`}>
          <div
            className="
              flex-shrink-0
              w-24
              h-20
              md:w-56
              md:h-40
              lg:w-72
              lg:h-48
              rounded-lg"
            style={{backgroundColor: `${background}`}}
              >
            <div className="h-full flex items-center justify-center text-6xl md:text-8xl text-white"><i className={icon}></i></div>
          </div>
          </a>
        )
      }

    <div className="ml-3 md:ml-6 flex flex-col flex-grow relative">
        <div className="flex flex-shrink">
          <div className="px-3 py-1 bg-blue-100 text-sm">{type}</div>
        </div>
        <div className="mt-3 font-medium">
          {type !== "Learning Path"
          ? (
            <a href={`/#/catalog/course-details/${id}`}>
              {title}
            </a>
          ) : (
            <a href={`/#/catalog/path-details/${id}`}>
              {title}
            </a>
          )
        }

        </div>
        <div className="overflow-hidden h-12 hidden md:block">{summary}</div>
        <div className="flex flex-row mt-3">
          <div className="text-gray-600 mb-3">
            <div className="flex flex-row">
              <div><i className="fa-light fa-clock"></i> {duration} mins</div><div className="ml-1 hidden md:block">- Released {release}</div>
            </div>
          </div>
        </div>

        {ratingCount !== 0 ?
          (
            <div className="flex flex-row items-center">
          <div>
            {rating.toFixed(1)}
          </div>
          <div className="ml-1 -mt-1">

            <StarRatings
              rating={rating}
              starRatedColor="#EEB32F"
              numberOfStars={5}
              starDimension="20px"
              starSpacing="1px"
            />

          </div>
          <div className="text-gray-600 ml-2">
            ({ratingCount})
          </div>
        </div>
      ) : null
      }
        <div className="hidden relative md:absolute right-0 bottom-0 mt-4">


      {type !== "In-Person Course" && price === 0
      ? (<Button type="secondary" size="sm" href="#">
        Start {type.split(' ')[1]}
        </Button>)
      : ( type !== "In-Person Course" && price > 0
        ? <Button type="secondary" size="sm" href="#">
          Add to Cart
          </Button>
        : (
          <Button type="secondary" size="sm" href="#">
            Schedule
            </Button>
        )
      )
    }


        </div>
      </div>

    </div>
  );
};

export default CatalogItem;
