import React from 'react';

const CartEmpty = () => {

  return (
    <div className="p-20 text-center">
      <div><i className="fa-light fa-shopping-cart text-5xl text-orange-500"></i></div>
      <div className="text-2xl my-4">You Shopping Cart is Empty</div>
      <a href="/#/catalog">Continue Shopping</a>
    </div>
  );
};

export default CartEmpty;
