import React from "react";
import Avatar from "../Avatar/Avatar";
import NumberFormat from 'react-number-format'

export const Input = ({ label, type, name, id, subLabel, placeholder }) => {
  return (
    <div>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className="h-10 pl-4 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md"
      />
    </div>
  );
};

export const Search = ({type, label, name, id, subLabel, icon, placeholder }) => {
  return (
    <div className="relative">
      <label
        htmlFor="text"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <i className="fa-light fa-search text-gray-500 absolute top-3 left-3"></i>
      <input
        type={type}
        name={name}
        id={id}
        className="h-10 pl-8 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md bg-gray-50"
        placeholder={placeholder}
      />
    </div>
  );
};


export const RadioButtons = ({ label, name, id, type }) => {
  return (
    <div className="flex flex-row items-center">
      <input
        id={id}
        name={name}
        type={type}
        className="focus:ring-transparent h-4 w-4 text-indigo-600 border-gray-400"
      />
      <label
        htmlFor="push-everything"
        className="ml-2 block text-sm"
      >
        {label}
      </label>
    </div>
  );
};

export const InputDollars = ({type, label, name, id, subLabel }) => {
  return (
    <div className="relative">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <i className="fa-light fa-dollar-sign text-gray-500 absolute top-9 left-3"></i>
      <input
        type={type}
        name={name}
        id={id}
        className="pl-6 mt-1 focus:ring-transparent focus:border-green-300 block w-full border-gray-400 rounded-md"
      />
    </div>
  );
};

export const InputNote = ({type, name, id, placehoder }) => {
  return (
    <div className="relative">
      <i className="fa-light fa-memo text-gray-500 absolute top-3.5 left-3"></i>
      <textarea
        type="text"
        name={name}
        id={id}
        className="pl-8 py-2.5 pr-2.5 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:border-green-400"
        placeholder="Type a note and press “Enter” …"
      />
    </div>
  );
};

export const InputComment = ({type, name, id, placehoder, img }) => {
  return (
    <div className="relative">
      <div className="absolute top-2.5 left-2.5">
        <Avatar img={img} size={40} color="gray-300" />
      </div>
      <textarea
        type="text"
        name={name}
        id={id}
        className="pl-16 py-2.5 pr-2.5 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:border-green-400"
        placeholder="Type a comment and press “Enter” …"
      />
    </div>
  );
};

export const PhoneNumber = ({value, label, subLabel, error}) => {

  return (
    <div>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="text-sm text-gray-500">{subLabel}</div>
      <NumberFormat
        value={value}
        format="(###) ###-####"
        className={`h-10 pl-4 mt-1 focus:outline-none focus:border-green-400 block w-full border border-gray-400 rounded-md ${error ? 'check-in-error' : ''}`}
        placeholder="(xxx) xxx-xxxx"
        id="phoneInput"
      />
  </div>
  );
};
