import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const data = { progress: "60%" };

const Match = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>
            <div className="flex">
              <div className="bg-green-100 flex flex-row items-center rounded-full px-2 py-1">
                <i class="fa-solid fa-circle-check mr-2 text-green-500 text-xl"></i>
                <div className="text-green-600 mr-1">We found a match.</div>
              </div>
            </div>
            <div className="text-xl">
              Is this you?
            </div>
            <div>
              <div className="text-lg font-medium">Tom Cook</div>
              <div>225-555-1212</div>
              <div>tcook@email.com</div>
              <div>iLevel Student ID: 938542</div>
            </div>
            <div className="flex flex-row">
              <div className="mr-3">
                <Button type="primary" size="md" href="/#/form-password">
                  Yes, It's Me
                </Button>
              </div>
              <div className="mr-3">
                <Button type="secondary" size="md" href="/#/form-email">
                  No, It's Not Me
                </Button>
              </div>
              <div>
                <Button type="tertiary" size="md" href="/#/form-phone">
                  Back
                </Button>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default Match;
