import React from 'react';

import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Catalog from './views/Catalog';
import CourseDetail from './views/Catalog/CourseDetails';
import PathDetail from './views/Catalog/PathDetails';
import Cart from './views/Checkout/Cart';
import Confirmation from './views/CreateAccount/Confirmation';
import EmailValidated from './views/CreateAccount/EmailValidated';
import FormAddress from './views/CreateAccount/FormAddress';
import FormEmail from './views/CreateAccount/FormEmail';
import FormMatchOtherFields from './views/CreateAccount/FormMatchOtherFields';
import FormPassword from './views/CreateAccount/FormPassword';
import FormPhone from './views/CreateAccount/FormPhone';
import Match from './views/CreateAccount/Match';
import ValidateEmail from './views/CreateAccount/ValidateEmail';
import ValidatePhone from './views/CreateAccount/ValidatePhone';
import Dashboard from './views/Dashboard';
import MyProfile from './views/MyProfile';
import SignIn from './views/SignIn';

function App() {

  return (
    <div>
      <Router>
          <Switch>
            <Route exact path="/" name="SignIn" component={SignIn} />
            <Route exact path="/form-phone" name="FormPhone" component={FormPhone} />
            <Route exact path="/validate-phone" name="ValidatePhone" component={ValidatePhone} />
            <Route exact path="/form-email" name="FormEmail" component={FormEmail} />
            <Route exact path="/validation-listening" name="ValidateEmail" component={ValidateEmail} />
            <Route exact path="/email-validated" name="EmailValidated" component={EmailValidated} />
            <Route exact path="/form-other" name="FormMatchOtherFields" component={FormMatchOtherFields} />
            <Route exact path="/form-address" name="FormAddress" component={FormAddress} />
            <Route exact path="/form-password" name="FormPassword" component={FormPassword} />
            <Route exact path="/confirmation" name="Confirmation" component={Confirmation} />
            <Route exact path="/match" name="Match" component={Match} />
            <Route exact path="/dashboard" name="Dashboard" component={Dashboard} />
            <Route exact path="/catalog" name="Catalog" component={Catalog} />
            <Route exact path="/catalog/course-details/:id" name="CourseDetail" component={CourseDetail} />
            <Route exact path="/catalog/path-details/:id" name="PathDetail" component={PathDetail} />
            <Route exact path="/cart" name="Cart" component={Cart} />
            <Route exact path="/my-profile" name="MyProfile" component={MyProfile} />
          </Switch>
      </Router>
    </div>
  );
}

export default App;
