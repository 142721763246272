import React from 'react';

const Status = {
  "Not Started": "gray",
  "In Progress": "blue",
  "Completed": "green",
  "Passed": "green",
  "Failed": "red"
}

const ProgressBar = ({status, width, progress, showPercent, showStatus, showProgress, target}) => {
  console.log({showStatus});

  const percentDone = Math.round(progress/target*100)

  return (
    <div className="flex flex-row items-center">
      {showPercent === true
        ? <div className={`text-${Status[status]}-500 mr-2 text-sm`}>{percentDone}%</div>
        : null
      }
      <div className={`bg-${Status[status]}-500 h-[8px] rounded z-10 -mr-1`} style={{width: width*percentDone/100}}></div>
      <div className={`bg-gray-300 h-[4px] rounded`} style={{width: width-(width*percentDone/100)}}></div>
      {percentDone === 100 && showStatus === true
        ? (<div className={`ml-3 text-sm text-${Status[status]}-500`}>{status}</div>)
        : null
      }
      {showProgress === true
        ? <div className="ml-3 text-sm text-gray-700">{progress} / {target}</div>
        : null
      }
    </div>
  );
};

export default ProgressBar;
