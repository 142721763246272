import React from "react";
import Filter from "../Form/Filter";
// import FilterRating from "../Form/FilterRating"; Post MVP
import {
  filterCategory,
  // filterVendor, Post MVP
  // filterType, Post MVP
  filterDuration,
  filterCost,
} from "../../data/Filters";

const FiltersPanel = () => {

  return (

    <div>
      <div className="border-b border-gray-300 mb-4 pb-4 lg:-ml-4 lg:pl-4">
        <div className="relative">
          <Filter category="Category" filters={filterCategory} />
        </div>
      </div>

      {/* Post MVP
      <div className="border-b border-gray-300 mb-4 pb-4 lg:-ml-4 lg:pl-4">
        <div className="relative">
          <Filter category="Vendor" filters={filterVendor} />
        </div>
      </div>
      */}

      {/* Post MVP when other Types become available
      <div className="border-b border-gray-300 mb-4 pb-4 lg:-ml-4 lg:pl-4">
        <div className="relative">
          <Filter category="Type" filters={filterType} />
        </div>
      </div>
      */}

      {/* Post MVP
      <div className="border-b border-gray-300 mb-4 pb-4 lg:-ml-4 lg:pl-4">
        <div className="relative">
          <FilterRating category="Rating" />
        </div>
      </div>
      */}

      <div className="border-b border-gray-300 mb-4 pb-4 lg:-ml-4 lg:pl-4">
        <div className="relative">
          <Filter category="Duration" filters={filterDuration} />
        </div>
      </div>

      <div className="border-b border-gray-300 mb-4 pb-4 lg:-ml-4 lg:pl-4">
        <div className="relative">
          <Filter category="Cost" filters={filterCost} />
        </div>
      </div>
    </div>

  );
};

export default FiltersPanel;
