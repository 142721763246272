import React from "react";
import TopNav from "../../components/Navigation/TopNav";
import EnrolledCourse from "../../components/Panels/EnrolledCourse";
// import ScheduledCourse from "../../components/Panels/ScheduledCourse"; Post MVP
// import RequiredCourse from "../../components/Panels/RequiredCourse"; Post MVP
import Tabs from "../../components/Navigation/Tabs";
import MainCard from "@alliancesafetycouncil/asc-card";
import courses from "../../data/MyCourses";
// import scheduled from "../../data/ScheduledCourses"; Post MVP
// import requirements from "../../data/RequiredCourses"; Post MVP

const Dashboard = () => {
  return (
    <div className="h-screen relative">
      <div className="fixed w-full z-20">
        <TopNav />
      </div>
      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 md:p-4">
        <div className="lg:col-span-2">
          <MainCard type="main">
            <div className="flex flex-col">
              <div className="font-medium text-xl mb-3">Enrolled</div>

              {/* Filters */}
              <Tabs />

              {/* Course Panels */}
              {courses.map((course) => (
                <div
                  className="pt-6 pb-6 border-b border-gray-300 last:border-0 last:pb-2"
                  key={course.id}
                >
                  <EnrolledCourse
                    key={course.id}
                    title={course.title}
                    type={course.type}
                    summary={course.summary}
                    status={course.status}
                    img={course.img}
                    target={course.target}
                    progress={course.progress}
                  />
                </div>
              ))}
            </div>
          </MainCard>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
