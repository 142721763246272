import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';

import TopNav from '../../components/Navigation/TopNav';
import CartEmpty from '../../components/Panels/CartEmpty';
import CartItem from '../../components/Panels/CartItem';
import cartItems from '../../data/CartItems';

const totalPrice = cartItems.reduce((total, currentValue) => total = total + currentValue.price,0);

const Cart = () => {
  return (
    <div className="relative">
      <div className="fixed w-full z-20">
        <TopNav />
      </div>
      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 md:p-4">
        <div className="mb-4">
          <PageTitle>
            <i className="far fa-shopping-cart text-orange-500 mr-2"></i>
            Shopping Cart
          </PageTitle>
        </div>
        <MainCard type="main">
          {cartItems.length > 0 ? (
            <div>
              {cartItems.map((item) => (
                <div
                  className="py-6 first:pt-6 border-b border-gray-300 last:border-0 last:pb-2 relative"
                  key={item.id}
                >
                  <CartItem
                    key={item.id}
                    title={item.title}
                    type={item.type}
                    summary={item.summary}
                    img={item.img}
                    id={item.id}
                    price={item.price}
                    background={item.background}
                    icon={item.icon}
                    location={item.location}
                    city={item.city}
                    state={item.state}
                    startTime={item.startTime}
                  />
                </div>
              ))}
              <div className="mt-4 flex flex-row">
                <div className="hidden md:block">
                  <Button type="tertiary" size="sm" href="/#/catalog">Continue Shopping</Button>
                </div>
                <div className="ml-3 hidden md:block">
                  <Button type="tertiary" size="sm" href="/#/catalog">Remove All Items</Button>
                </div>
                <div className="flex flex-grow flex-col justify-end">
                    <div className="text-xl font-medium text-right">
                      Total: {totalPrice.toFixed(2)}
                    </div>
                    <div className="text-right mt-1 text-sm text-gray-600">
                      Enter promo codes on the checkout page.
                    </div>
                    <div className="text-right my-4">
                      <Button type="primary" size="sm" href="/#/checkout">Proceed to Checkout</Button>
                    </div>

                </div>
              </div>
            </div>

          ) : (
            <CartEmpty />
          )}
        </MainCard>
      </div>
    </div>
  );
};

export default Cart;
