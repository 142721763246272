const courses = [
  {
    id: 1,
    title: "Operating Heavy Tools Safely 101",
    type: "Online Course",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    status: "Not Started",
    target: 19,
    progress: 0,
    img: "https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg"
  },
  {
    id: 2,
    title: "The Essentials of Welding",
    type: "Online Course",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    status: "In Progress",
    target: 146,
    progress: 32,
    img: "https://images.pexels.com/photos/2397414/pexels-photo-2397414.jpeg"
  },
  {
    id: 3,
    title: "Blue Plant Beginner Orientation",
    type: "Online Course",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    status: "Completed",
    progress: 62,
    target: 62,
    img: "https://images.pexels.com/photos/844297/pexels-photo-844297.jpeg"
  },
  {
    id: 4,
    title: "Communication Strategies",
    type: "Online Course",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    status: "Failed",
    target: 90,
    progress: 90,
    img: "https://images.pexels.com/photos/4245826/pexels-photo-4245826.jpeg"
  }
]

export default courses;
