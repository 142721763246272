import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { Input } from '../../components/Form/Input';
import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const data = {progress: "40%"}

const FormEmail = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>
            <div>
              <Input label="Email Address" type="text" subLabel="We will email you a link to validation your email account." />
            </div>
            <div className="flex flex-row">
              <div>
                <Button type="primary" size="md" href="/#/validation-listening">Continue</Button>
              </div>
              <div className="ml-3">
                <Button type="tertiary" size="md" href="/#/form-phone">Back</Button>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default FormEmail;
