import React from 'react';

export const Logo = () => {

  return (
    <>
      <div>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.88 40">
          <path fill="#1B91C7"  d="M10.2,3.56a5.12,5.12,0,0,1,1.07.38,28.23,28.23,0,0,1,3.15,1.38A16.8,16.8,0,0,0,14,1.21S11.86,0,7.21,0h0C2.84,0,.71,1.07.47,1.2A63.7,63.7,0,0,1,10.2,3.56Z"/>
          <path fill="#333333"  d="M6,4.82c-1.07-.28-2-.53-2.81-.68h0c-1-.2-2.07-.35-3.12-.46a13.07,13.07,0,0,0,0,2.42c.54.1,1.65.31,2.92.59H3a45.06,45.06,0,0,1,8,2.5,7.32,7.32,0,0,1-3.69,3.57h0a7.21,7.21,0,0,1-3.85-4c-.59-.1-1.69-.27-2.95-.4A12,12,0,0,0,7.21,16h0a11.93,11.93,0,0,0,6.91-8.17A54,54,0,0,0,6,4.82Z"/>
          <path fill="#333333"  d="M27,4.78a3.5,3.5,0,0,1-.76,2.3,4.84,4.84,0,0,1-2.2,1.44l4.3,5.19H25L21.34,9h-.86v4.69H17.8V.86h3.81a9.33,9.33,0,0,1,2.18.24,5.32,5.32,0,0,1,1.71.73,3.56,3.56,0,0,1,1.12,1.23A3.5,3.5,0,0,1,27,4.78Zm-2.68,0a1.51,1.51,0,0,0-.76-1.42,3.91,3.91,0,0,0-2-.45H20.48V7h.8a4.05,4.05,0,0,0,2.22-.54A1.79,1.79,0,0,0,24.34,4.83Z"/>
          <path fill="#333333"  d="M29.7.86h7.65V3h-5V6.11h4.74V8.25H32.38v3.32h5v2.14H29.7Z"/>
          <path fill="#333333"  d="M51.36,13.71H48.58l-.9-2.7H42.44l-.9,2.7H38.77L43.61.86h2.91ZM45.06,3,43.15,8.87H47Z"/>
          <path fill="#333333"  d="M53.06.86H56.9A9.22,9.22,0,0,1,60,1.34a6.91,6.91,0,0,1,2.33,1.34,6,6,0,0,1,1.49,2,6.21,6.21,0,0,1,.53,2.56,6.22,6.22,0,0,1-.53,2.57,6,6,0,0,1-1.49,2A6.93,6.93,0,0,1,60,13.23a9.22,9.22,0,0,1-3.06.48H53.06ZM57,11.57a6,6,0,0,0,1.88-.29,4.41,4.41,0,0,0,1.48-.85,3.7,3.7,0,0,0,1-1.34,4.49,4.49,0,0,0,.35-1.81,4.48,4.48,0,0,0-.35-1.8,3.74,3.74,0,0,0-1-1.35,4.57,4.57,0,0,0-1.48-.84A6,6,0,0,0,57,3H55.74v8.57Z"/>
          <path fill="#333333"  d="M72.61.86h2.93l-4.21,8v4.81H68.65V8.9l-4.21-8h2.93L70,6.51Z"/>
          <path fill="#1B91C7"  d="M84.26,10.43A3.27,3.27,0,0,1,84,11.87a3.37,3.37,0,0,1-.88,1.1,4.15,4.15,0,0,1-1.35.72,5.61,5.61,0,0,1-1.73.25,9.2,9.2,0,0,1-1.86-.2,7.79,7.79,0,0,1-1.67-.55l.28-1.06a9.11,9.11,0,0,0,1.57.54,7.59,7.59,0,0,0,1.65.19,3.49,3.49,0,0,0,2.2-.64A2.08,2.08,0,0,0,83,10.49a2.2,2.2,0,0,0-.24-1.05,2.86,2.86,0,0,0-.65-.79,4.72,4.72,0,0,0-1-.62c-.35-.19-.72-.37-1.11-.55s-.83-.42-1.23-.65a5.7,5.7,0,0,1-1.07-.76,3.55,3.55,0,0,1-.76-1,2.62,2.62,0,0,1-.28-1.25,3.13,3.13,0,0,1,.3-1.4,3.12,3.12,0,0,1,.82-1A3.68,3.68,0,0,1,79,.83a5.5,5.5,0,0,1,1.5-.2,8.6,8.6,0,0,1,1.7.17,7.14,7.14,0,0,1,1.5.5l-.27,1.1A6.74,6.74,0,0,0,82,1.89a6.37,6.37,0,0,0-1.48-.18,3.18,3.18,0,0,0-1.88.5A1.77,1.77,0,0,0,78,3.75a2.13,2.13,0,0,0,.14.85,1.79,1.79,0,0,0,.45.62,4.12,4.12,0,0,0,.77.53l1.12.59L81.77,7A7.16,7.16,0,0,1,83,7.85a4.3,4.3,0,0,1,.91,1.09A3,3,0,0,1,84.26,10.43Z"/>
          <path fill="#1B91C7"  d="M97,13.71H95.62l-1.24-3.28H88.22L87,13.71H85.62l5-12.85h1.26ZM91.3,2.21,88.62,9.35H94Z"/>
          <path fill="#1B91C7"  d="M99.18,13.71V.86H106V1.94h-5.55V6.88h5.32V8h-5.32v5.75Z"/>
          <path fill="#1B91C7"  d="M108.37.86h6.81V1.94h-5.51V6.59H115V7.67h-5.28v5h5.56v1.08h-6.86Z"/>
          <path fill="#1B91C7"  d="M5.14,20.38V35.89h7.09v3.76H.4V20.38Z"/>
          <path fill="#1B91C7"  d="M14.64,20.38h12v3.76H19.38V28H26.3v3.76H19.38v4.11h7.35v3.76H14.64Z"/>
          <path fill="#1B91C7"  d="M41.92,35.92H34.54l-1.18,3.73H28.47l7.17-19.27h5.21L48,39.65H43.14Zm-6.16-3.76h5L38.25,24Z"/>
          <path fill="#1B91C7"  d="M64.78,26.49a5.48,5.48,0,0,1-1.08,3.38,6.71,6.71,0,0,1-3,2.2L67,39.65H61.16L55.87,33H55v6.68H50.26V20.38h6.13a14,14,0,0,1,3.33.38,8.13,8.13,0,0,1,2.66,1.13,5.24,5.24,0,0,1,2.4,4.6Zm-4.57.08a2.1,2.1,0,0,0-1-1.92A5.39,5.39,0,0,0,56.36,24H55v5.41h1.16a5.15,5.15,0,0,0,3-.75A2.46,2.46,0,0,0,60.21,26.57Z"/>
          <path fill="#1B91C7"  d="M81.27,39.65l-8-11.48V39.65H68.75V20.38h4.57l8,11.49V20.38h4.58V39.65Z"/>
          <path fill="#1B91C7"  d="M94.5,39.65H89.75V20.38H94.5Z"/>
          <path fill="#1B91C7"  d="M110.93,39.65l-8-11.48V39.65H98.4V20.38H103l8,11.49V20.38h4.57V39.65Z"/>
          <path fill="#1B91C7"  d="M129,20a15.09,15.09,0,0,1,6.31,1.18l-.64,3.57a13.94,13.94,0,0,0-5.21-1,8.42,8.42,0,0,0-2.6.37,5.06,5.06,0,0,0-2,1.17,5.31,5.31,0,0,0-1.28,2,8.59,8.59,0,0,0-.44,2.94,6.06,6.06,0,0,0,1.57,4.54,6.42,6.42,0,0,0,4.65,1.53,7.51,7.51,0,0,0,1.27-.1,7.21,7.21,0,0,0,1-.24V30.65h4.26v8a13.61,13.61,0,0,1-3.17,1,20.2,20.2,0,0,1-4,.39,12.46,12.46,0,0,1-4.34-.71,9.29,9.29,0,0,1-3.24-2,8.53,8.53,0,0,1-2-3.05,10.68,10.68,0,0,1-.71-3.95,12,12,0,0,1,.71-4.21,8.67,8.67,0,0,1,2.07-3.24,9.13,9.13,0,0,1,3.33-2.09A12.71,12.71,0,0,1,129,20Z"/></svg>
      </div>
    </>
  );
};

export const SubLogo = () => {

  return (
    <>
      <div>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 28">
        <path fill="#1B91C7" d="M17.69,6a8.66,8.66,0,0,1,1.85.67A46.71,46.71,0,0,1,25,9.09a29.24,29.24,0,0,0-.76-7.2S20.57-.24,12.5-.24h0C4.92-.24,1.23,1.64.81,1.86A109.12,109.12,0,0,1,17.69,6Z" transform="translate(0 0.24)"/>
        <path fill="#1B91C7" d="M10.39,8.22C8.54,7.72,6.9,7.29,5.51,7h0C3.74,6.67,1.92,6.4.1,6.21a25.2,25.2,0,0,0,0,4.25c1,.18,2.87.54,5.07,1h0A78.22,78.22,0,0,1,19,15.88a12.64,12.64,0,0,1-6.41,6.26h0a12.56,12.56,0,0,1-6.67-7c-1-.18-2.92-.48-5.11-.7A20.91,20.91,0,0,0,12.5,27.76h0a20.81,20.81,0,0,0,12-14.33A91,91,0,0,0,10.39,8.22Z" transform="translate(0 0.24)"/>
        </svg>
      </div>
    </>
  );
};
