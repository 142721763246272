import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { Input } from '../../components/Form/Input';
import { Logo } from '../../components/Logo/Logo';

const SignIn = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-3 text-2xl">Sign In</div>
            <div>
              <Input label="Email Address" type="text" name="email" id="email" />
            </div>
            <div>
              <Input label="Password" type="password" name="password" id="password" />
            </div>
            <div>
              <Button type="primary" size="md" href="/#/dashboard">Sign In</Button>
            </div>
            <div className="flex flex-col mb-3">
              <a href="/#/">Forgot Password</a>
              <a href="/#/form-phone">Create Account</a>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default SignIn;
