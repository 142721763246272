import React from "react";

export const filterCategory = [
  {
  value: 1,
  label: 'Language',
  children: [
    {label: 'English', value: 2},
    {label: 'French', value: 3},
    {label: 'Spanish', value: 4}
    ]
  },
  {
  value: 5,
  label: 'Safety',
  children: [
    {label: 'Driving Safety', value: 6},
    {label: 'Safety Awareness', value: 7}
    ]
  },
  {
  value: 8,
  label: 'Soft Skills',
  children: [
    {label: 'Business', value: 9},
    {label: 'Communication', value: 10},
    {label: 'Emotional Intelligence', value: 11},
    {label: 'Leadership', value: 12},
    {label: 'Management', value: 13},
    {label: 'Sales and Marketing', value: 14},
    {label: 'Self-Management', value: 15},
    {label: 'Teamwork', value: 16}
    ]
  },
  {
  value: 17,
  label: 'Workplace Safety',
  children: [
    {label: 'Active Shooter', value: 18},
    {label: 'Back Safety', value: 19},
    {label: 'Chemical Safety', value: 20},
    {label: 'Confined Safety', value: 21},
    {label: 'Construction Safety', value: 22},
    {label: 'Crane Safety', value: 23},
    {label: 'Electrical Safety', value: 24},
    {label: 'Emergency Response', value: 25},
    {label: 'Environmental', value: 26},
    {label: 'Equipment Safety', value: 27},
    {label: 'Ergonomics', value: 28},
    {label: 'fa-lightl Prevention', value: 29},
    {label: 'Fire Safety', value: 30},
    {label: 'First Aid', value: 31},
    {label: 'Hazard Communication', value: 32},
    {label: 'Hazardous Materials', value: 33},
    {label: 'Hearing Conservation', value: 34},
    {label: 'Incedent Investigation', value: 35},
    {label: 'Off-the-Job Safety', value: 36},
    {label: 'OSHA', value: 37},
    {label: 'Personal Protective Equipment', value: 38},
    {
    value: 39,
    label: 'Safety',
    children: [
      {label: 'Driving Safety', value: 40},
      {label: 'Safety Awareness', value: 41}
      ]
    },
    {
    value: 42,
    label: 'Safe Driving'
  },
  {
    value: 43,
    label: 'Tool Safety'
  },
    ]
  }
];

export const filterVendor = [
  { label: 'AlertDriving', value: 44 },
  { label: 'Alliance Safety Council', value: 45 },
  { label: 'CRG', value: 46 },
  { label: 'UL', value: 47 }
];

export const filterType = [
  { label: 'Courses', value: 'course' },
  { label: 'Learning Paths', value: 'path' }
];


export const filterRating = [
  { label: <div><i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> & Up</div>, value: '4star' },
  { label: <div><i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> & Up</div>, value: '3star' },
  { label: <div><i className="fas fa-star"></i> <i className="fas fa-star"></i> & Up</div>, value: '2star' },
  { label: <div><i className="fas fa-star"></i> & Up</div>, value: '1star' }
];

export const filterDuration = [
  { label: '0-30 Minutes', value: '0-30min' },
  { label: '31-60 Minutes', value: '31-60min' },
  { label: '61-120 Minutes', value: '61-120min' },
  { label: '121-180 Minutes', value: '121-180min' },
  { label: '181+ Minutes', value: '181+min' }
];

export const filterCost = [
  { label: 'Free', value: 0 },
  { label: '$0.01 - 25.00', value: 25 },
  { label: '$25.01 - 100.00', value: 100 },
  { label: '$101+', value: 101 }
];
