import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TopNav from "../../components/Navigation/TopNav";
import CourseDetail from "../../components/Panels/CourseDetail";
// import Tools from "../../components/ContentTools/CourseTools"; Post MVP
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";

const baseURL = "https://62ab6284bd0e5d29af0f1cce.mockapi.io/Catalog";

export default function Details(props) {
  const { id } = useParams();

  const [course, setCourse] = useState(null);

  useEffect(() => {
    axios.get(`${baseURL}?id=${id}`).then((response) => {
      setCourse(response.data);
    });
  }, []);

  if (!course) return null;

  return (
    <div>
      <div className="fixed w-full z-20">
        <TopNav />
      </div>

      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 lg:p-4 mt-2 lg:mt-0">
        {course.map((item) => (
          <div key={item.id}>
            <div className="mb-4 flex flex-col md:flex-row items-center">
              <div>
                <PageTitle>
                <i className="fa-regular fa-book-open text-orange-500 mr-2 mb-3 md:mb-0"></i>
                {item.title}
              </PageTitle>
            </div>
            <div>
              {item.enrolled ? (
                <div className="right-2 lg:right-0 bg-green-500 rounded-lg py-1 px-2 flex justify-self-start flex-row items-center">
                  <div className="text-white"><i class="fa-light fa-check mr-2"></i>Enrolled</div>
                </div>
              ) : null
            }
            </div>
            </div>
            <MainCard type="main">
                <div className="bg-black rounded-lg">
                  <div className="relative w-full overflow-hidden rounded-lg lg:rounded-none flex justify-center">
                    {item.enrolled ? (
                      <div className="w-[954px]">
                      <iframe
                        width="100%"
                        title={item.title}
                        className="h-48 sm:h-[460px]"
                        src={item.video}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    ) : (
                      <img
                        className="w-full h-40 sm:h-80 lg:h-96 object-center object-cover rounded-lg"
                        src={item.img}
                        alt={item.title}
                      />
                    )}
                  </div>
                </div>
                <div className="text-xl font-medium mb-3 mt-3">
                  Course Details
                </div>
                <div>
                  <CourseDetail
                    id={item.id}
                    img={item.img}
                    type={item.type}
                    background={item.background}
                    icon={item.icon}
                    duration={item.duration}
                    release={item.release}
                    rating={item.rating}
                    ratingCount={item.ratingCount}
                    description={item.description}
                    title={item.title}
                    enrolled={item.enrolled}
                    price={item.price}
                  />
                </div>

            </MainCard>
          </div>
        ))}
      </div>
    </div>
  );
}
