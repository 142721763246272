import React from 'react';

import MainCard from '@alliancesafetycouncil/asc-card';

import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const data = {progress: "40%"}

const ValidateEmail = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>
            <a href="/#/email-validated">
              <div className="text-gray-500 flex md:items-center flex-row p-3 rounded-lg bg-gray-100">
                <div>
                  <i class="fas fa-check-circle text-xl mr-2 text-gray-300"></i>
                </div>
                <div>
               Email sent. Click the validation link in your email to continue.
               </div>
             </div>
             <div className="text-center mt-4">
               <a href="/#/">Resend Link</a>
             </div>
           </a>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default ValidateEmail;
