import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { PhoneNumber } from '../../components/Form/Input';
import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const data = {progress: "20%"}

const FormPhone = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>

            <div>
              <PhoneNumber label="Mobile Number" subLabel="We will text you a validation code. Message and data rates may apply." />
            </div>
            <div className="flex flex-row">
              <div>
                <Button type="primary" size="md" href="/#/validate-phone">Continue</Button>
              </div>
              <div className="ml-3">
                <Button type="tertiary" size="md" href="/#/">Back</Button>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default FormPhone;
