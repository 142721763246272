import React from "react";
// import StarRatings from "react-star-ratings"; Post MVP
import Button from "@alliancesafetycouncil/asc-button";

const CourseDetail = ({
  id,
  img,
  type,
  background,
  icon,
  duration,
  release,
  rating,
  ratingCount,
  description,
  title,
  price,
  enrolled,
}) => {
  const DisplayPrice = () =>
    <div className="text-xl font-medium mr-4">
      {price !== 0 ? "$" + price.toFixed(2) : "Free"}
    </div>;

    const DisplayCTA = () =>
    <div>
    {type !== "In-Person Course" && price === 0 ? (
      <Button type="primary" size="sm" href="#">
        Start {type.split(" ")[1]}
      </Button>
    ) : type !== "In-Person Course" && price > 0 ? (
      <Button type="primary" size="sm" href="#">
        Add to Cart
      </Button>
    ) : (
      <Button type="primary" size="sm" href="#">
        Schedule
      </Button>
    )}
    </div>

  return (
    <div className="grid grid-col-1 gap-2 relative">
      <div className="text-gray-600 flex flex-row">
        <div>
          <i className="fa-light fa-clock"></i> {duration} mins
        </div>
        <div className="ml-1">- Released {release}</div>
      </div>

      {/* Post MVP
      {ratingCount !== 0 ? (
        <div className="flex flex-row items-center">
          <div>{rating.toFixed(1)}</div>
          <div className="ml-1 -mt-1">
            <StarRatings
              rating={rating}
              starRatedColor="#EEB32F"
              numberOfStars={5}
              starDimension="20px"
              starSpacing="1px"
            />
          </div>
          <div className="text-gray-600 ml-2">({ratingCount})</div>
        </div>
      ) : null}
      */}

      {enrolled ? null : (
        <div className="flex flex-row items-center mt-2">
          <DisplayPrice />
          <DisplayCTA />
        </div>
      )}

      <div className="mt-3">{description}</div>
    </div>
  );
};



export default CourseDetail;
