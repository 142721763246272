import React from 'react';
import CheckboxTree from 'react-checkbox-tree';

class Filter extends React.Component {
    state = {
        checked: [],
        expanded: [],
    };

    render() {
        return (
          <div>
            <div className="font-medium text-xl mb-3">{this.props.category}</div>
            <CheckboxTree
                nodes={this.props.filters}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={checked => this.setState({ checked })}
                onExpand={expanded => this.setState({ expanded })}
                onClick="someAction"
                expandOnClick={true}
                icons={{
                  expandClose: <i className="fa-light fa-angle-down text-xl"></i>,
                  expandOpen: <i className="fa-light fa-angle-up text-xl"></i>,
                  uncheck: <i className="fa-light fa-square text-lg text-gray-600 mr-2 cursor-pointer"></i>,
                  check: <i className="fa-solid fa-check-square text-lg text-blue-500 mr-2 cursor-pointer"></i>,
                  halfCheck: <i className="fa-solid fa-minus-square text-lg text-blue-500 mr-2 cursor-pointer"></i>
              }}
            />
          </div>
        );
    }
}

export default Filter;
