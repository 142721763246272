import React from 'react';
import { Link } from "react-router-dom";
import ProgressBar from '../ProgressBar/ProgressBar';

const EnrolledCourse = ({title, type, summary, status, target, img, width, progress, showStatus, showPercent}) => {


  return (
      <div className="flex flex-row">

          <div className="
            flex-shrink-0
            w-24
            h-20
            md:w-40
            md:h-24
            bg-cover
            bg-center
            bg-no-repeat
            rounded-lg"
            style={{ backgroundImage: `url(${img})` }}
            ></div>
          <div className="ml-3 md:ml-4 flex flex-col flex-grow">
          <div className="flex flex-shrink">
            <div className="px-3 py-1 bg-blue-100 text-sm">{type}</div>
          </div>
          <div className="mt-3 font-medium">
            <Link to="#" key={title}>{title}</Link>
          </div>
          {/* <div className="overflow-hidden h-6">{summary}</div> */}
          <div className="flex flex-row items-center mt-3">
            <ProgressBar
              width={150}
              target={target}
              progress={progress}
              showPercent={true}
              showStatus={true}
              showProgress={false}
              status={status}
             />
            <div>
              <div className="hidden md:block ml-6 text-sm text-gray-700"><i className="fa-light fa-clock"></i> {target} mins</div>
            </div>
          </div>

        </div>
      </div>
  );
};

export default EnrolledCourse
