import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import Button from "@alliancesafetycouncil/asc-button";
import FiltersPanel from "../Panels/FiltersPanel";

const FilterModal = () => {
  const [open, setOpen] = useState(false)

  return (
    <div>
    <Link to="#" onClick={() => setOpen(true)}><i className="fa-light fa-filter mr-1"></i>Filters</Link>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500 md:duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500 md:duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex w-full mb-0 md:mb-4">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 md:duration-200"
                enterFrom="translate-y-full md:opacity-0 md:translate-y-0"
                enterTo="translate-y-0 md:opacity-100 md:translate-y-0"
                leave="transform transition ease-in-out duration-500 md:duration-200"
                leaveFrom="translate-y-0 md:opacity-100 md:translate-y-0"
                leaveTo="translate-y-full md:opacity-0 md:translate-y-0"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen md:w-5/6 mt-20 bg-white shadow-xl rounded-t-xl md:rounded-xl pt-3 pb-20 mx-auto">
                    <Dialog.Title className="flex flex-row items-center px-4 border-b border-gray-300 pb-4 pt-1">
                      <div className="flex items-center text-lg font-medium flex-grow">
                        <i className="fa-light fa-filter mr-1"></i> Filters
                      </div>
                      <div>
                        <Link to="#" onClick={() => setOpen(false)} className="outline-none focus:outline-none">
                          <i className="fa-light fa-times text-xl"></i>
                        </Link>
                      </div>
                      </Dialog.Title>
                  <div className="flex h-full flex-col overflow-y-scroll">

                    <div className="relative mt-3 flex-1 px-4 sm:px-6">
                      <div>
                        <FiltersPanel />
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-0 h-16 bg-white w-full grid grid-cols-1 items-center px-4 rounded-none md:rounded-b-xl">
                    <Button type="primary" size="sm"><span className="mx-auto">Apply</span></Button>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  );
};

export default FilterModal;
