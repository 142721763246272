import React from 'react';

const Avatar = ({img, size, color, editable}) => {

  return (
    <div className="flex overflow-hidden relative p-1 -m-1">
      {img.length ?
        (
          <img
            style={{height: size, width: size}}
            className={`inline-block rounded-full ring-2 ring-white`}
            src={img}
            alt=""
          />
      ) : <div style={{height: size, width: size}}><i style={{fontSize: size}} class={`fa-solid fa-circle-user text-${color}`}></i></div>
      }
    </div>
  );
};

export default Avatar;
