import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import TopNav from "../../components/Navigation/TopNav";
import MainCard from "@alliancesafetycouncil/asc-card";
import { Search } from "../../components/Form/Input";
import Sort from "../../components/Navigation/Sort";
import Paginate from "../../components/Navigation/Pagination";
import CatalogItem from "../../components/Panels/CatalogItem";
import FiltersPanel from "../../components/Panels/FiltersPanel";
import FilterModal from "../../components/Modal/FilterModal";
import sortList from "../../data/CatalogSort";

const baseURL = 'https://62ab6284bd0e5d29af0f1cce.mockapi.io/Catalog'

export default function Catalog() {

  const [course, setCourse] = useState(null);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setCourse(response.data);
    });
  }, []);

  if (!course) return null;

  return (
    <div className="h-screen relative">
      <div className="fixed w-full z-20">
        <TopNav />
      </div>
      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 md:p-4">
        <MainCard type="main">
          <div className="relative">
            <Search
              type="text"
              name="search"
              id="search"
              placeholder="Search"
            />
          </div>
          <div className="py-4 border-b border-gray-300 flex flex-row items-center z-10">

            <div className="block lg:hidden z-50">
              <FilterModal />
            </div>

            <div className="hidden lg:flex flex-row items-center">
              <div className="mr-2 text-sm font-medium">Filters:</div>
              <div>
                <Link to="#" className="py-2 rounded-lg border border-blue-500 px-2 text-sm">
                  Learning Paths<i className="fa-light fa-times ml-2"></i>
                </Link>
              </div>
              <div className="ml-2">
                <Link to="#" className="text-sm">Clear All</Link>
              </div>
            </div>

            <div className="flex flex-grow justify-end">
              <Sort items={sortList} label="Sort by" />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
            {/* Column 1: Filters */}
            <div className="hidden lg:block mt-4">
              <FiltersPanel />
            </div>

            {/* Column 2: Catalog Items */}
            <div className="col-span-1 lg:col-span-4">

                  <div>
                {course.map((item) => (
                  <div>
                    {item.type === "Online Course" ? (
                  <div
                    className="py-6 first:pt-6 border-b border-gray-300 relative"
                    key={item.id}
                  >
                    <CatalogItem
                      key={item.id}
                      title={item.title}
                      type={item.type}
                      summary={item.summary}
                      duration={item.duration}
                      thumb={item.thumb}
                      release={item.release}
                      rating={item.rating}
                      ratingCount={item.ratingCount}
                      id={item.id}
                      price={item.price}
                      background={item.background}
                      icon={item.icon}
                    />
                  </div>
                ) : null }
                </div>
                ))}
              </div>

              <div className="mt-8 mb-4 flex items-center justify-center">
                <Paginate itemsCount={25} totalItems={450} pageRange={5} />
              </div>


            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};
