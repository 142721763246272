import React from "react";
import { Link } from "react-router-dom";
import Sort from "../Navigation/Sort";
import sortList from "../../data/DashboardSort";

const Tabs = () => {


  return (

    <div className="text-center border-b border-gray-300 flex flex-col md:flex-row">
      <div className="flex flex-grow">
        <ul className="flex flex-wrap -mb-px">
          <li>
            <Link
              to="#"
              className="inline-block px-2 md:px-4 pb-3 text-blue-500 rounded-t-lg border-b-2 border-blue-500 active dark:text-blue-500 dark:border-blue-500"
              aria-current="page"
            >
              All
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="text-gray-600 inline-block px-2 md:px-4 pb-3 rounded-t-lg border-b-2 border-transparent hover:text-blue-500 hover:border-blue-500"
            >
              Not Started
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="text-gray-600 inline-block px-2 md:px-4 pb-3 rounded-t-lg border-b-2 border-transparent hover:text-blue-500 hover:border-blue-500"
            >
              In Progress
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="text-gray-600 inline-block px-2 md:px-4 pb-3 rounded-t-lg border-b-2 border-transparent hover:text-blue-500 hover:border-blue-500"
            >
              History
            </Link>
          </li>
        </ul>
      </div>
      <div className="py-4 md:py-0">
        <Sort items={sortList} label="Sort by" />
      </div>

    </div>

  );
}

export default Tabs;
