import React, { Component } from "react";
import Pagination from "react-js-pagination";

class Paginate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
  }

  render() {
    return (
      <div className="flex items-start">
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.props.itemsCount}
          totalItemsCount={this.props.totalItems}
          pageRangeDisplayed={this.props.pageRange}
          onChange={this.handlePageChange.bind(this)}
          innerClass="flex border border-gray-300 rounded-xl shadow-md"
          itemClass="py-2 px-3 sm:px-5"
          activeClass="border border-blue-500"
          activeLinkClass="font-bold"
          firstPageText=<i className="fa-light fa-angle-double-left text-xl"></i>
          lastPageText=<i className="fa-light fa-angle-double-right text-xl"></i>
          prevPageText=<i className="fa-light fa-angle-left text-xl"></i>
          nextPageText=<i className="fa-light fa-angle-right text-xl"></i>
        />
      </div>
    );
  }
}

export default Paginate;
