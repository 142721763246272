import React from 'react';

import { Link } from 'react-router-dom';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import Avatar from '../../components/Avatar/Avatar';
import DropDown from '../../components/Form/DropDown';
import { Input } from '../../components/Form/Input';
import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const data = {progress: "80%"}
const options = [
  {item: "LA"},
  {item: "MD"},
  {item: "TN"}
]

const user = {
  name: 'Tom Cook',
  imageUrl:
    '' // 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const FormAddress = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>
              <div className="flex flex-row items-center">
                <div className="relative">
                  <Avatar img={user.imageUrl} size={100} color="gray-300" />
                  <div className="absolute right-0 bottom-0">
                    <Link to="#">
                      <div className="flex h-8 w-8 bg-gray-500 hover:bg-gray-600 rounded-full justify-center items-center ring-2 ring-white">
                        <i class="fa-light fa-camera text-white text-xl"></i>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="ml-3 flex flex-col">
                  <div className="text-lg font-medium">{user.name}</div>
                  <div><a href="mailto:tcook@email.com">tcook@email.com</a></div>
                  <div>(225) 555-1212</div>
                </div>
              </div>
            <div>
              <Input type="text" label="Address 1" />
            </div>
            <div>
              <Input type="text" label="Address 2" />
            </div>
            <div>
              <div className="col-span-1"><Input type="text" label="City" /></div>
            </div>
            <div>
              <div className="col-span-1"><DropDown options={options} label="State" /></div>
            </div>
            <div>
              <div className="col-span-1"><Input type="text" label="Zip Code" name="zip-code" id="zip-code" /></div>
            </div>
            <div>
              <Input type="password" label="Password" />
              </div>
              <div>
                <Input type="password" label="Confirm Password" />
              </div>
            <div className="flex flex-row">
              <div>
                <Button type="save" size="md" href="/#/confirmation">Complete Registration</Button>
              </div>
              <div className="ml-3">
                <Button type="tertiary" size="md" href="/#/form-other">Back</Button>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default FormAddress;
