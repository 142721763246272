import React from "react";
import { Link } from "react-router-dom";

const CartItem = ({ id, title, type, img, price, background, icon, location, startTime, city, state }) => {

  return (
    <div className="flex flex-row relative">
      <div className="absolute right-0 top-0 font-medium">
          {price !== 0.00
            ? ("$" + price.toFixed(2))
            : "Free"
          }
      </div>

      {type !== "Learning Path" ?
        (
          <div
            className="
              flex-shrink-0
              w-24
              h-20
              md:w-40
              md:h-24
              bg-cover
              bg-center
              bg-no-repeat"
          style={{ backgroundImage: `url(${img})` }}
          ></div>
        ) : (
          <div
            className="
              flex-shrink-0
              w-24
              h-20
              md:w-40
              md:h-24"
            style={{backgroundColor: `${background}`}}
              >
            <div className="h-full flex items-center justify-center text-6xl text-white">{icon}</div>
          </div>
        )
      }

    <div className="ml-3 md:ml-6 flex flex-col flex-grow relative">
        <div className="flex flex-shrink">
          <div className="px-3 py-1 bg-blue-100 text-sm">{type}</div>
        </div>
        <div className="mt-3 font-medium">
          <Link to="#" key={title}>
            {title}
          </Link>
        </div>

        {type === "In-Person Course"
          ? (
            <div className="mt-2 flex flex-col md:flex-row">
              <div className="mr-6 flex flex-row">
                <div className="w-5"><i className="fa-light fa-calendar-alt"></i></div>
                <div>Tomorow, {startTime}</div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-row">
                  <div className="w-5"><i className="fa-light fa-map-marker-alt"></i></div>
                  <div>{location},</div>
                </div>
                <div className="ml-5 md:ml-1">{city}, {state}</div>
              </div>
            </div>
          ) : (
            null
          )
        }
        <div className="mt-2 text-sm"><Link to="#">remove</Link></div>
      </div>

    </div>
  );
};

export default CartItem;
